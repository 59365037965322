import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { ref, computed } from '@vue/composition-api'
import { _getProcesses } from '@/@core/queries/process'
import i18n from '@/libs/i18n'
import { get, set, getMany, update } from 'idb-keyval';
import awsConnection from '@/views/habit/aws';
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import { useRouter } from '@core/utils/utils'
import endpoints from '@/libs/endpoints'
import realmConnection from '@/views/habit/realm'

export default function useCommon() {
  const { role, worker_id, client, locations: userLocations } = store.state?.userStore?.userData
  const userLocationsQuery = userLocations?.map(e => ({ _id: e.value }))
  const { commitment_functionality } = JSON.parse(localStorage.getItem("clientData") || '{}')
  const { showErrorMessage } = useNotifications()
  const { singleUpload, getFile } = awsConnection()
  const { updateImprovementWithKey, sendEmailImprovements } = useCommonTodo()
  const { router } = useRouter()
  const { getItem, getItems, getItemsWithAggregate, updateItem, updateItems, createItem, ObjectId, invokeFunction } = realmConnection()
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

  const mainQuery = {
    client_id: ObjectId(client.$oid),
    deleted: { $ne: true }
  }

  const roles = ref([])
  const workersTotal = ref([])
  const workersTotalNotMapped = ref([])
  const workersSupervised = ref([])
  const workersSupervisedNotMapped = ref([])
  const supervisors = ref([])
  const supervisorsOnlyNames = ref([])
  const locations = ref([])
  const workerPrivileges = ref([])
  const privileges = ref([])
  const processes = ref([])
  const motives = ref([])
  const metadata = ref([])
  const metadataNotMapped = ref([])
  const metadataFilterOptions = ref({zone: [], agency: []})
  const metadataTypeSelect = ref([])
  const metadataTypeSelectNotMapped = ref({})
  const customFilterOptions = ref([])

  // Main IndexedDB/LocalStorage data keys that are essential for offline mode
  const storageDataKeys = [ "events", "locations", "processesComplete", "workersSupervised" ]

  const checkStorageData = () => {
    getMany(storageDataKeys)
      .then(values => {
        for (const value of values) {
          if (!value) {
            for (const key of storageDataKeys) {
              if (!localStorage[key]) {
                showErrorMessage(i18n.t('message.storage_data_not_detected'), false)
                break
              }
            }
            break
          }
        }
      })
  }

  const updateStorageData = () => {
    fetchAndStoreEvents()
    getRolesForDropDown()
    getWorkersTotalForDropDown("notMapped")
    getWorkersSupervisedForDropDown()
    getWorkersSupervisedForDropDown("notMapped")
    getSupervisorsForDropDown()
    getSupervisorsForDropDown("onlyNames")
    getLocationsForDropDown()
    getProcessesForDropDown()
    getMetadataForDropDown({ category: "improvement", option: "notMapped" })
    getMetadataForDropDown({ type: "select", option: "notMapped" })
    getMotivesForDropDown()
  }

  const storeDataToUpload = (storageProperty, payload) => {
    update(storageProperty, response => {
      if (response) {
        response.push({...payload})
        return response
      } else {
        return [{...payload}]
      }
    })
  }

  const handleError = ({error, defaultMessage, isStorageError}) => {
    if (isStorageError) return showErrorMessage(i18n.t('message.storage_data_fetch_error'))
    if (typeof error === 'string' && error.startsWith("timeout") || error?.message?.startsWith("timeout")) {
      return showErrorMessage(i18n.t('message.internet_connection_unstable'))
    }
    return showErrorMessage(defaultMessage)
  }

  const formatDatePicker = (date, hour = 12) => {
    let formattedDate
    if (date instanceof Date) {
      formattedDate = new Date(date)
      formattedDate.setHours(hour, 0, 0, 0)
    } else {
      formattedDate = new Date(`${date} ${hour}:00:00`)
    }
    return formattedDate
  }

  const formatIsoDate = (dateStr) => {
    const date = new Date(dateStr)
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    const format = default_language === 'en' ? 'en-US' : 'es-AR'
    return date.toLocaleDateString(format, options)
}

  const getRolesForDropDown = async () => {
    get('roles').then(response => {
      if (response) roles.value = response
      else if (localStorage.roles) roles.value = JSON.parse(localStorage.roles)
    })

    try {
      const items = await getItems({ collection: 'role', query: mainQuery, options: { projection : { name: 1 }, sort: { name: 1 } } })

      roles.value = items?.map(({ name, _id }) => ({ title: name, value: _id.toString() })) || []
      set('roles', roles.value).then(() => {}).catch(error => console.log(error))
    } catch (error) {
      console.log(error)
      get('roles').then(response => {
        if (!response && !localStorage.roles) handleError({ isStorageError: true })
      })
    }
  }

  const getWorkersTotalForDropDown = async (option) => {
    if (option === "notMapped") {
      get('workersTotalNotMapped').then(response => {
        if (response) workersTotalNotMapped.value = response
        else if (localStorage.workersTotalNotMapped) workersTotalNotMapped.value = JSON.parse(localStorage.workersTotalNotMapped)
      })
    } else {
      get('workersTotal').then(response => {
        if (response) workersTotal.value = response
        else if (localStorage.workersTotal) workersTotal.value = JSON.parse(localStorage.workersTotal)
      })
    }

    try {
      const pipeline = [
        { $match: mainQuery },
        { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'worker', localField: 'supervisors', foreignField: '_id', as: 'supervisors', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $project: { name: 1, email: 1, supervisors: 1, locations: 1 } },
        { $sort: { name: 1 } },
        { $addFields: { _id: { $toString: "$_id" } } }
      ]

      const items = await getItemsWithAggregate({ collection: 'worker', pipeline })

      if (option === "notMapped") {
        workersTotalNotMapped.value = items || []
        set('workersTotalNotMapped', workersTotalNotMapped.value).then(() => {}).catch(error => console.log(error))
      } else {
        workersTotal.value = items?.map(({ name, _id }) => ({ title: name, value: _id })) || []
        set('workersTotal', workersTotal.value).then(() => {}).catch(error => console.log(error))
      }
    } catch (error) {
      console.log(error)
      if (option === "notMapped") {
        get('workersTotalNotMapped').then(response => {
          if (!response && !localStorage.workersTotalNotMapped) handleError({ isStorageError: true })
        })
      } else {
        get('workersTotal').then(response => {
          if (!response && !localStorage.workersTotal) handleError({ isStorageError: true })
        })
      }
    }
  }

  const getWorkersSupervisedForDropDown = async (option, addId) => {
    if (option === "notMapped") {
      get('workersSupervisedNotMapped').then(response => {
        if (response) workersSupervisedNotMapped.value = response
        else if (localStorage.workersSupervisedNotMapped) workersSupervisedNotMapped.value = JSON.parse(localStorage.workersSupervisedNotMapped)
      })
    } else {
      get('workersSupervised').then(response => {
        if (response) workersSupervised.value = response
        else if (localStorage.workersSupervised) workersSupervised.value = JSON.parse(localStorage.workersSupervised)
      })
    }

    try {
      const reformattedQuery = { ...mainQuery }

      if (role === 'supervisor') {
        reformattedQuery.$or = [{ supervisors: ObjectId(worker_id.$oid) }]

        // Add ID of the logged-in supervisor for improvements only
        if (addId === "addId") { 
          reformattedQuery.$or.push({ _id: ObjectId(worker_id.$oid) } )
        }
      }

      const pipeline = [
        { $match: reformattedQuery },
        { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'worker', localField: 'supervisors', foreignField: '_id', as: 'supervisors', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $project: { name: 1, email: 1, supervisors: 1, locations: 1 } },
        { $sort: { name: 1 } },
        { $addFields: { _id: { $toString: "$_id" } } }
      ]

      const items = await getItemsWithAggregate({ collection: 'worker', pipeline })

      if (option === "notMapped") {
        workersSupervisedNotMapped.value = items || []
        set('workersSupervisedNotMapped', workersSupervisedNotMapped.value).then(() => {}).catch(error => console.log(error))
      } else {
        workersSupervised.value = items?.map(({ name, _id }) => ({ title: name, value: _id })) || []
        set('workersSupervised', workersSupervised.value).then(() => {}).catch(error => console.log(error))
      }
    } catch (error) {
      console.log(error)
      if (option === "notMapped") {
        get('workersSupervisedNotMapped').then(response => {
          if (!response && !localStorage.workersSupervisedNotMapped) handleError({ isStorageError: true })
        })
      } else {
        get('workersSupervised').then(response => {
          if (!response && !localStorage.workersSupervised) handleError({ isStorageError: true })
        })
      }
    }
  }

  const getSupervisorsForDropDown = async (option, workerId) => {
    if (option === "onlyNames") {
      get('supervisorsOnlyNames').then(response => {
        if (response) supervisorsOnlyNames.value = response
        else if (localStorage.supervisorsOnlyNames) supervisorsOnlyNames.value = JSON.parse(localStorage.supervisorsOnlyNames)
      })
    } else {
      get('supervisors').then(response => {
        if (response) supervisors.value = response
        else if (localStorage.supervisors) supervisors.value = JSON.parse(localStorage.supervisors)
      })
    }

    try {
      const reformattedQuery = { ...mainQuery, $or: [{ privileges: 'supervisor' }] }

      if (role !== 'supervisor') reformattedQuery.$or.push({ privileges: 'consultant' })
      
      // Exclude the worker that is being edited so that it can't be assigned as its own supervisor
      if (workerId) reformattedQuery._id = { $ne: ObjectId(workerId) }
      
      const pipeline = [
        { $match: reformattedQuery },
        { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', as: 'locations', pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'worker', localField: 'supervisors', foreignField: '_id', as: 'supervisors', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $project: { name: 1, email: 1, supervisors: 1, locations: 1 } },
        { $sort: { name: 1 } },
        { $addFields: { _id: { $toString: "$_id" } } }
      ]

      const items = await getItemsWithAggregate({ collection: 'worker', pipeline })

      if (option === "onlyNames") {
        supervisorsOnlyNames.value = Array.from(new Set(items?.map(({ name }) => name) || []))
        set('supervisorsOnlyNames', supervisorsOnlyNames.value).then(() => {}).catch(error => console.log(error))
      } else {
        supervisors.value = items?.map(({ name, _id, locations }) => ({ title: name, value: _id, locationIds: locations.map(e => e._id), locationNames: locations.map(e => e.location) })) || []
        set('supervisors', supervisors.value).then(() => {}).catch(error => console.log(error))
      }
    } catch (error) {
      console.log(error)
      if (option === "onlyNames") {
        get('supervisorsOnlyNames').then(response => {
          if (!response && !localStorage.supervisorsOnlyNames) handleError({ isStorageError: true })
        })
      } else {
        get('supervisors').then(response => {
          if (!response && !localStorage.supervisors) handleError({ isStorageError: true })
        })
      }
    }
  }

  const getLocationsForDropDown = async () => {
    get('locations').then(response => {
      if (response) locations.value = response
      else if (localStorage.locations) locations.value = JSON.parse(localStorage.locations)
    })

    try {
      const items = await getItems({ collection: 'location', query: mainQuery, options: { projection : { location: 1 }, sort: { location: 1 } } })

      locations.value = items?.map(({ location, _id }) => ({ title: location, value: _id.toString() })) || []
      set('locations', locations.value).then(() => {}).catch(error => console.log(error))
    } catch (error) {
      console.log(error)
      get('locations').then(response => {
        if (!response && !localStorage.locations) handleError({ isStorageError: true })
      })
    }
  }

  const getProcessesForDropDown = async () => {
    get('processesDropdown').then(response => {
      if (response) processes.value = response
      else if (localStorage.processesDropdown) processes.value = JSON.parse(localStorage.processesDropdown)
    })

    try {
      const input = {
        limit: 10000,
        sortBy: 'name',
        client_id: client.$oid,
        worker_id: worker_id.$oid,
        role,
      }

      const items = await invokeFunction({ name: 'processesQueryWithOffset', arg: input })

      processes.value = items?.processes?.map(e => {
        e._id = e._id.toString()
        if (e.metadataData) {
          // This variable cannot come as 'metadata' from the backend because it interferes with the process schema validation
          e.metadata = e.metadataData
          delete e.metadataData
        }
        return { title: e.name, value: e._id }
      }) || []
      set('processesComplete', items?.processes || []).then(() => {}).catch(error => console.log(error))
      set('processesDropdown', processes.value).then(() => {}).catch(error => console.log(error))
    } catch (error) {
      console.log(error)
      get('processesDropdown').then(response => {
        if (!response && !localStorage.processesDropdown) handleError({ isStorageError: true })
      })
    }
  }

  const getMotivesForDropDown = async () => {
    get('motives').then(response => {
      if (response) motives.value = response
      else if (localStorage.motives) motives.value = JSON.parse(localStorage.motives)
    })

    try {
      const items = await getItems({ collection: 'motive', query: mainQuery, options: { projection : { value: 1, label: 1 }, sort: { label: 1 } } })
      items?.forEach(e => e._id = e._id.toString())

      motives.value = items || []
      set('motives', motives.value).then(() => {}).catch(error => console.log(error))
    } catch (error) {
      console.log(error)
      get('motives').then(response => {
        if (!response && !localStorage.motives) handleError({ isStorageError: true })
      })
    }
  }
  
  const getMetadataForDropDown = async ({category, option, type}) => {
    if (category === "filter") {
      get('metadataFilterOptions').then(response => {
        if (response) {
          response.forEach(e => {
            Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
          })
        } else if (localStorage.metadataFilterOptions) {
          JSON.parse(localStorage.metadataFilterOptions).forEach(e => {
            Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
          })
        }
      })
    }
    else if (option === "notMapped") {
      if (type === "select") {
        get('metadataTypeSelectNotMapped').then(response => {
          if (response) metadataTypeSelectNotMapped.value = response
          else if (localStorage.metadataTypeSelectNotMapped) metadataTypeSelectNotMapped.value = JSON.parse(localStorage.metadataTypeSelectNotMapped)
        })
      } else {
        get('metadataNotMapped').then(response => {
          if (response) metadataNotMapped.value = response
          else if (localStorage.metadataNotMapped) metadataNotMapped.value = JSON.parse(localStorage.metadataNotMapped)
        })
      }
    }
    else if (type === "select") {
      get('metadataTypeSelect').then(response => {
        if (response) metadataTypeSelect.value = response
        else if (localStorage.metadataTypeSelect) metadataTypeSelect.value = JSON.parse(localStorage.metadataTypeSelect)
      })
    }
    else {
      get('metadata').then(response => {
        if (response) metadata.value = response
        else if (localStorage.metadata) metadata.value = JSON.parse(localStorage.metadata)
      })
    }

    try {
      const reformattedQuery = { ...mainQuery }
      if (category) reformattedQuery.categories = category
      if (type) reformattedQuery.type = type

      const items = await getItems({ collection: 'metadata', query: reformattedQuery, options: { projection : { name: 1, type: 1, options: 1 }, sort: { name: 1 } } })
      items?.forEach(e => e._id = e._id.toString())

      if (category === "filter") {
        items.forEach(e => {
          Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
        })
        set('metadataFilterOptions', items).then(() => {}).catch(error => console.log(error))
      }
      else if (option === "notMapped") {
        if (type === "select") {
          items.forEach(e => {
            Object.assign(metadataTypeSelectNotMapped.value, {[e.name]: e.options})
          })
          set('metadataTypeSelectNotMapped', metadataTypeSelectNotMapped.value).then(() => {}).catch(error => console.log(error))
        } else {
          metadataNotMapped.value = items || []
          set('metadataNotMapped', items).then(() => {}).catch(error => console.log(error))
        }
      }
      else {
        const metadataResponseMapped = computed(() => {
          return items?.map(({ name, _id }) => ({ title: i18n.t(`metadata.${name}`), value: _id }))
        })
        if (type === "select") {
          metadataTypeSelect.value = metadataResponseMapped
          set('metadataTypeSelect', metadataResponseMapped).then(() => {}).catch(error => console.log(error))
        }
        else {
          metadata.value = metadataResponseMapped
          set('metadata', metadataResponseMapped).then(() => {}).catch(error => console.log(error))
        }
      }
    } catch (error) {
      console.log(error)
      if (category === "filter") {
        get('metadataFilterOptions').then(response => {
          if (!response && !localStorage.metadataFilterOptions) handleError({ isStorageError: true })
        })
      }
      else if (option === "notMapped") {
        if (type === "select") {
          get('metadataTypeSelectNotMapped').then(response => {
            if (!response && !localStorage.metadataTypeSelectNotMapped) handleError({ isStorageError: true })
          })
        }
        get('metadataNotMapped').then(response => {
          if (!response && !localStorage.metadataNotMapped) handleError({ isStorageError: true })
        })
      }
      else if (type === "select") {
        get('metadataTypeSelect').then(response => {
          if (!response && !localStorage.metadataTypeSelect) handleError({ isStorageError: true })
        })
      }
      else {
        get('metadata').then(response => {
          if (!response && !localStorage.metadata) handleError({ isStorageError: true })
        })
      }
    }
  }

  const getCustomFilterForDropDown = async () => {
    try {
      const items = await getItems({ collection: 'custom_filter', query: mainQuery, options: { projection : { name: 1, locations: 1, process: 1, role: 1, supervisor: 1, worker: 1 }, sort: { name: 1 } } })
      
      items?.forEach(e => {
        e._id = e._id.toString()
        e.locations = e.locations?.map(l => l.toString()) || []
        e.process = e.process?.map(p => p.toString()) || []
        e.role = e.role?.map(r => r.toString()) || []
        e.supervisor = e.supervisor?.map(s => s.toString()) || []
        e.worker = e.worker?.map(w => w.toString()) || []
      })

      customFilterOptions.value = items || []
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAndStoreEvents = async () => {
    const calendars = ["Pendientes", "Vencidas"]

    const now = new Date()
    const firstDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

    try {
      const initialQuery = {
        ...mainQuery,
        'extendedProps.calendar': { $in: calendars },
        start: { $gte: firstDayOfPreviousMonth, $lt: firstDayOfNextMonth },
      }

      const finalQuery = {
        $or: [{ "attendee.deleted": { $ne: true } }, { attendee: { $exists: false } }]
      }

      if (role === 'supervisor') {
        finalQuery.$or[0]["attendee.supervisors"] = ObjectId(worker_id.$oid)
        finalQuery.$or[1]["organizer.locations"] = { $in: userLocationsQuery.map(e => ObjectId(e._id)) }
        finalQuery.$or[2] = { "attendee._id": worker_id.$oid }
      }

      const pipeline = [
        { $match: initialQuery },
        { $lookup: { from: 'worker', localField: 'attendee', foreignField: '_id', as: 'attendee', pipeline: [ { $project: { deleted: 1, supervisors: 1, name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'worker', localField: 'organizer', foreignField: '_id', as: 'organizer', pipeline: [ { $project: { locations: 1 } } ] } },
        { $addFields: { attendee: { $arrayElemAt: ["$attendee", 0] }, organizer: { $arrayElemAt: ["$organizer", 0] } } },
        { $match: finalQuery },
        { $lookup: { from: 'process', localField: 'process', foreignField: '_id', as: 'process', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'confirmation', localField: 'confirmation', foreignField: '_id', as: 'confirmation', pipeline: [ { $project: { pending: 1, supervisor: 1 } }, { $addFields: { _id: { $toString: "$_id" } } }, { $lookup: { from: 'worker', localField: 'supervisor', foreignField: '_id', as: 'supervisor', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $addFields: { supervisor: { $arrayElemAt: ["$supervisor", 0] } } } ] } },
        { $lookup: { from: 'commitment', localField: 'commitment', foreignField: '_id', as: 'commitment', pipeline: [ { $project: { completed: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $lookup: { from: 'improvement', localField: 'improvements', foreignField: '_id', as: 'improvements', pipeline: [ { $addFields: { _id: { $toString: "$_id" }, assistance: { $toString: "$assistance" }, dueDate: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$dueDate" } } } }, { $lookup: { from: 'worker', localField: 'assignee', foreignField: '_id', as: 'assignee', pipeline: [ { $project: { name: 1, email: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $lookup: { from: 'worker', localField: 'subscribers', foreignField: '_id', as: 'subscribers', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } }, { $addFields: { assignee: { $arrayElemAt: ["$assignee", 0] } } } ] } },
        { $lookup: { from: 'worker', localField: 'participants', foreignField: '_id', as: 'participants', pipeline: [ { $project: { _id: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
        { $addFields: { _id: { $toString: "$_id" }, assistance: { $toString: "$assistance" }, process: { $arrayElemAt: ["$process", 0] }, confirmation: { $arrayElemAt: ["$confirmation", 0] }, commitment: { $arrayElemAt: ["$commitment", 0] }, start: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$start" } }, end: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$end" } }, modifiedAt: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$modifiedAt" } } } },
      ]

      const items = await getItemsWithAggregate({ collection: 'event', pipeline })

      const events = items || []
      set('events', events).then(() => {}).catch(error => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  const updateConfirmationWithKey = async (confirmationId, key) => {
    try {
      const query = { _id: ObjectId(confirmationId) }
      const action = { $set: { imageKey: key } }

      await updateItem({ collection: 'confirmation', query, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_update_error'))
    }
  }

  const createImprovements = async (improvements, origin, originId, sendEmail = true) => {
    if (!improvements?.length) return

    const improvementCreationPromises = improvements.map(i => {
      const newImprovement = {
        client_id: ObjectId(i.client_id),
        assignee: i.assignee ? ObjectId(i.assignee._id) : null,
        note: i.note,
        tags: i.tags,
        description: i.description,
        dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
        important: i.important,
      }
      if (origin) newImprovement.origin = origin
      if (originId) newImprovement.origin_id = ObjectId(originId)
      if (i.metadata?.length) {
        newImprovement.metadata = i.metadata.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        }))
      }
      if (i.subscribers?.length) newImprovement.subscribers = i.subscribers.map(s => ObjectId(s))

      return new Promise(async (resolve, reject) => {
        try {    
          const { insertedId } = await createItem({ collection: 'improvement', payload: newImprovement })
          const newImprovementId = insertedId.toString()
          i._id = newImprovementId
          i.isNew = true
          
          const { fileInfo, destinationFolder } = i.imgData
          if (fileInfo) {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => updateImprovementWithKey(newImprovementId, key))
              .catch((err) => console.log(err))
          }

          resolve(i)
        } catch (error) {
          console.log(error)
          showErrorMessage(commitment_functionality ? i18n.t('message.commitment_error') : i18n.t('message.improvement_error'))
          resolve(null)
        }
      })
    })

    const resolvedPromises = await Promise.all(improvementCreationPromises)

    const filteredImprovements = resolvedPromises.filter(e => !!e)

    // Send email to assignee and subscribers with the details of the improvements created
    if (filteredImprovements?.length && sendEmail) {
      sendEmailImprovements(filteredImprovements, 'add')
    }

    return filteredImprovements.map(e => e._id)
  }

  const createCommitment = async (commitment) => {
    try {    
      const { insertedId } = await createItem({ collection: 'commitment', payload: commitment })
      return createBehaviourEvaluationEvent(commitment, insertedId)
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.commitment_error'))
    }
  }

  const createBehaviourEvaluationEvent = async (commitment, newCommitmentId) => {
    const endDate = new Date(commitment.dueDate)
    endDate.setHours(13, 0, 0, 0)

    const payload = {
      client_id: commitment.client_id,
      attendee: commitment.assignee,
      commitment: newCommitmentId,
      title: commitment.title,
      start: commitment.dueDate,
      end: endDate,
      extendedProps: { calendar: 'Pendientes' },
      isBehaviourEvaluation: true
    }

    try {    
      await createItem({ collection: 'event', payload })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_create_error'))
    }
  }

  const getEventId = async (property, id) => {
    try {
      const query = { [property]: ObjectId(id) }
      const item = await getItem({ collection: 'event', query })
      return item?._id?.toString()
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_update_error'))
    }
  }

  const updateCommitmentOfEvent = async (commitmentId, eventDate) => {
    const dueDate = eventDate instanceof Date ? eventDate : new Date(eventDate)

    try {
      const query = { _id: ObjectId(commitmentId) }
      const action = { $set: { dueDate } }

      await updateItem({ collection: 'commitment', query, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.commitment_update_error'))
    }
  }

  const handleAssistance = (assistanceId, improvementId) => {
    const routeData = assistanceId
      ? router.resolve({ name: 'habit-assistance-view', params: { assistanceId } })
      : router.resolve({ name: 'habit-assistance-new', params: { improvementId } })
    window.open(routeData.href, '_blank')
  }

  const openImage = async (imageKey) => {
    let w = window.open("");
    w.document.write(`<h2>${i18n.t('message.loading_image')}</h2>`);
    try {
      const imgFromAWS = await getFile(endpoints.aws_bucket, imageKey)
      let image = new Image();
      image.src = imgFromAWS;

      w.document.body.innerHTML = "";
      w.document.write(image.outerHTML);
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.err_open_image'))
    }
  }

  function getPrivilegesForWorker() {
    let defaultPrivilege = []
    if (role === 'supervisor') {
      defaultPrivilege = [{ value: 'employee', title: i18n.t('userRoles.Employee') }]
    } else if (role === 'consultant') {
      defaultPrivilege = [
        { value: 'consultant', title: i18n.t('userRoles.Consultant') },
        { value: 'supervisor', title: i18n.t('userRoles.Supervisor') },
        { value: 'employee', title: i18n.t('userRoles.Employee') },
      ]
    } else if (role === 'client') {
      defaultPrivilege = [
        { value: 'consultant', title: i18n.t('userRoles.Consultant') },
        { value: 'supervisor', title: i18n.t('userRoles.Supervisor') },
        { value: 'employee', title: i18n.t('userRoles.Employee') },
      ]
    } else {
      defaultPrivilege = [
        { value: 'admin', title: 'Admin' },
        { value: 'consultant', title: i18n.t('userRoles.Consultant') },
        { value: 'supervisor', title: i18n.t('userRoles.Supervisor') },
        { value: 'employee', title: i18n.t('userRoles.Employee') },
      ]
    }
    workerPrivileges.value = defaultPrivilege
  }

  function generateObjectId() {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
   
    return objectId;
  }

  // Open AWS documents
  const openDocument = async (key) => {
    if (!key) {
      showErrorMessage(i18n.t('message.document_not_available'))
      return
    }
    const url = `https://${endpoints.aws_bucket}.s3.amazonaws.com/${key}`
    window.open(url)
  }

  const getWorkersByRoles = async (roles) => {
    try {
      const conditions = {
        client_id: ObjectId(client.$oid),
        deleted: { $ne: true },
        roles: { $in: roles.map(e => ObjectId(e)) }
      }

      const workers = await getItems({ collection: 'worker', query: conditions })

      return workers
    } catch (error) {
      showErrorMessage(i18n.t('message.worker_fetch_error'));
    }
  }

  const getWorkersByChildRoles = async (childRoles) => {
    try {
      const conditions = {
        _id: { $in: childRoles.map(e => ObjectId(e)) }
      }

      const childRolesData = await getItems({ collection: 'role', query: conditions })
      if (!childRolesData?.length) return []

      const parentRolesSet = new Set()

      childRolesData.forEach(role => {
        role.parentRoles?.forEach(parent => {
          parentRolesSet.add(parent.toString())
        })
      })

      const parentRoles = Array.from(parentRolesSet)
      if (!parentRoles?.length) return []

      const parentWorkers = await getWorkersByRoles(parentRoles)

      return parentWorkers

    } catch (error) {
      showErrorMessage(i18n.t('message.worker_fetch_error'));
    }
  }

  const assignSupervisorsBasedOnRoles = async (childRoles, parentRoles = []) => {
    try {
      const childWorkers = await getWorkersByRoles(childRoles)
      if (!childWorkers?.length) return

      const parentWorkers = parentRoles.length ? await getWorkersByRoles(parentRoles) : await getWorkersByChildRoles(childRoles)

      const query = {
        _id: { $in: childWorkers.map(e => e._id) }
      }

      const payload = {
        supervisors: parentWorkers?.map(e => e._id) || [],
      }

      const action = { $set: payload }

      updateItems({ collection: 'worker', query, action })
    } catch (error) {
      showErrorMessage(i18n.t('message.err_assigning_supervisors'));
    }
  }

  return {
    getRolesForDropDown,
    roles,
    getWorkersTotalForDropDown,
    workersTotal,
    workersTotalNotMapped,
    getWorkersSupervisedForDropDown,
    workersSupervised,
    workersSupervisedNotMapped,
    getSupervisorsForDropDown,
    supervisors,
    supervisorsOnlyNames,
    privileges,
    getProcessesForDropDown,
    processes,
    getLocationsForDropDown,
    locations,
    getPrivilegesForWorker,
    workerPrivileges,
    getMotivesForDropDown,
    motives,
    getMetadataForDropDown,
    metadata,
    metadataNotMapped,
    metadataFilterOptions,
    metadataTypeSelect,
    metadataTypeSelectNotMapped,
    handleError,
    checkStorageData,
    updateStorageData,
    fetchAndStoreEvents,
    storeDataToUpload,
    formatDatePicker,
    createCommitment,
    updateConfirmationWithKey,
    createImprovements,
    getEventId,
    updateCommitmentOfEvent,
    customFilterOptions,
    getCustomFilterForDropDown,
    handleAssistance,
    openImage,
    generateObjectId,
    openDocument,
    formatIsoDate,
    assignSupervisorsBasedOnRoles,
  }
}